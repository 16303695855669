import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import router from '@/router'
import axios from '@/libs/axios'
import store from '@/store'
import useFilterCounter from '@/components/Filters/useFiltersCount'
import { debounce } from 'lodash'


export default function useList() {

  const refListTable = ref(null)
  const toast = useToast()
  const listFilter = ref(null)

  store.dispatch('center/getAll')
  const centerOptions = computed(() => store.getters['center/getSelectedItems'])
  const centerFilter = ref(null)

  store.dispatch('sector/getAll')
  const sectorOptions = computed(() => store.getters['sector/getSelectedItems'])
  const sectorFilter = ref(null)

  store.dispatch('unit/getAll', { with: 'router:id,id_tenant' })
  const unitOptions = computed(() => store.getters['unit/getSelectedItems'])

  store.dispatch('camera_type/getAll')
  const cameraTypeOptions = computed(() => store.getters['camera_type/getSelectedItems'])
  const cameraTypeFilter = ref(null);

  const onlineFilter = ref(null)
  const rtspAvailableFilter = ref(null)
  const enabledOnvifEventFilter = ref(null)
  
  store.dispatch('tenant/getAll')
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  store.dispatch('omniview_reseller/getAll');
  const resellerOptions = computed(() => store.getters['omniview_reseller/getSelectedItems'])
  const resellerFilter = ref(null)

  if (router.currentRoute.params.type)
    listFilter.value = parseInt(router.currentRoute.type)

  const tableColumns = [
    {
      label: 'actions.name',
      key: 'actions'
    },
    // {
    //   label: 'generic_devices.status',
    //   key: 'title',
    //   // sortable: true
    // },
    {
      label: 'generic_devices.name',
      key: 'name',
      sortable: true
    },
    {
      label: 'generic_devices.provider',
      key: 'provider',
      sortable: true
    },
    {
      label: 'generic_devices.type',
      key: 'type',
      sortable: true
    },
    {
      label: 'generic_devices.idCameraProxy',
      key: 'id_camera_proxy'
    },
    {
      label: 'generic_devices.data',
      key: 'data',
      sortable: true
    },
    {
      label: 'camera_proxy.rtsp_available',
      key: 'rtsp_available',
      sortable: true
    },
    {
      label: 'centers.title',
      key: 'center.name'
    },
    {
      label: 'sectors.title',
      key: 'sector.name'
    },
    {
      label: 'generic_devices.camera_type',
      key: 'camera_type_name'
    },
    {
      label: 'generic_devices.reseller',
      key: 'omniview_reseller_name'
    },
    {
      label: 'generic_devices.units',
      key: 'units',
      formatter: (value, key, item) => {
        if (value) return value.map(item => { return item.description }).join(', ')
        return ""
      }
    }
  ]

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('generic_devices/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      ...filters.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  const contentToast = (message, type) => toast({
    component: ToastificationContent,
    props: {
      title: message,
      icon: 'CheckIcon',
      variant: type
    }
  })

  const deleteItem = async (id) => {
    let { data } = await axios.delete(`/v1/generic-devices/${id}`);
    let { message, success } = data

    if (!success) {
      contentToast('Error deleting item', 'danger')
    } else {
      contentToast(message, 'success')
      refetchData()
    }
  };

  watch([
    listFilter,
    currentPage,
    perPage,
    ], () => {
      refetchData()
    })

  watch([
    searchQuery
  ], debounce(() => {
    refetchData()
    }, 500))

  // filters counter ============================
  const filters = ref({});
  const filtersCount = useFilterCounter(filters);
  const buildFilters = () => {
    filters.value = {
      center: centerFilter.value,
      tenant: tenantFilter.value,
      sector: sectorFilter.value,
      online: onlineFilter.value,
      reseller: resellerFilter.value,
      camera_type: cameraTypeFilter.value,
      rtsp_available: rtspAvailableFilter.value,
      enabled_onvif_events: enabledOnvifEventFilter.value,
    };
    refetchData();
  }
  const clearSearchFilters = () => {
    centerFilter.value = null;
    tenantFilter.value = null;
    sectorFilter.value = null;
    onlineFilter.value = null;
    rtspAvailableFilter.value = null;
    enabledOnvifEventFilter.value = null;
    resellerFilter.value = null;
    cameraTypeFilter.value = null;
    filters.value = {};
    buildFilters();
  }
  // ============================================

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,

    unitOptions,
    centerOptions,
    centerFilter,
    sectorOptions,
    sectorFilter,
    cameraTypeOptions,
    cameraTypeFilter,
    tenantOptions,
    tenantFilter,
    rtspAvailableFilter,
    onlineFilter,
    resellerOptions,
    resellerFilter,
    enabledOnvifEventFilter,

    listFilter,

    refetchData,
    contentToast,
    deleteItem,

    // filters counter
    filtersCount,
    buildFilters,
    clearSearchFilters,
  }
}
