<template>
  <div>
    <b-row align-v="center" class="d-flex align-items-center">
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $tc("centers.title", 2) }}</label>
        <v-select
          :value="centerFilter"
          :options="centerOptions"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="(val) => val.value"
          multiple
          @input="(val) => $emit('update:centerFilter', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $tc("sectors.title", 2) }}</label>
        <v-select
          :value="sectorFilter"
          :options="sectorOptions"
          class="w-100"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:sectorFilter', val)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $tc("tenants.title", 2) }}</label>
        <v-select
          :value="tenantFilter"
          :options="tenantOptions"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:tenantFilter', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2 text-capitalize">
        <label class="text-capitalize">{{ $t("camera.online") }}</label>
        <v-select
          :value="onlineFilter"
          :options="[
            { label: 'online', value: true },
            { label: 'offline', value: false },
          ]"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:onlineFilter', val)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $t("camera_proxy.rtsp_available") }}</label>
        <v-select
          :value="rtspAvailableFilter"
          :options="[
            { label: 'enabled', value: true },
            { label: 'disabled', value: false },
          ]"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:rtspAvailableFilter', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $t("generic_devices.reseller") }}</label>
        <v-select
          :value="resellerFilter"
          :options="resellerOptions"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:resellerFilter', val)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $t("generic_devices.camera_type") }}</label>
        <v-select
          :value="cameraTypeFilter"
          :options="cameraTypeOptions"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:cameraTypeFilter', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $t("generic_devices.form.custom_fields.enabled_onvif_events.label") }}</label>
        <v-select
          :value="enabledOnvifEventFilter"
          :options="[
            { label: 'enabled', value: true },
            { label: 'disabled', value: false },
          ]"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:enabledOnvifEventFilter', val)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@/libs/axios";
import { onMounted, ref } from "@vue/composition-api";
import popperHelper from "@/libs/popper-helper";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    centerOptions: {
      type: Array,
      default: null,
    },
    centerFilter: {
      type: Array,
      default: null,
    },
    sectorOptions: {
      type: Array,
      default: null,
    },
    sectorFilter: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    onlineFilter: {
      type: Boolean,
      default: false,
    },
    rtspAvailableFilter: {
      type: Boolean,
      default: false,
    },
    enabledOnvifEventFilter: {
      type: Boolean,
      default: false,
    },
    resellerOptions: {
      type: Array,
      default: null,
    },
    resellerFilter: {
      type: Number,
      default: null,
    },
    cameraTypeOptions: {
      type: Array,
      default: null,
    },
    cameraTypeFilter: {
      type: Array,
      default: null,
    },
  },
  setup(_, { emit }) {
    onMounted(async () => {
      let requestMasterAccounts = { isList: true };

      let { data } = await axios.get("/v1/services/luxriot/master-accounts", {
        params: requestMasterAccounts,
      });

      let { data: dataApi, success } = data;

      if (!success) {
        masterAccounts.value = [];
      }

      masterAccounts.value = dataApi;
    });

    //Data
    const masterAccounts = ref([]);

    return {
      masterAccounts,
      withPopper: popperHelper.withPopper,
    };
  },
};
</script>
