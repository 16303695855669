<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{ $t("generic_devices.edit_device") }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="$t('generic_devices.form.name.label')"
            rules="required"
          >
            <b-form-group :label="$t('generic_devices.form.name.label')" label-for="name">
              <b-form-input
                id="name"
                v-model="itemData.name"
                :placeholder="$t('generic_devices.form.name.placeholder')"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Provider -->
          <validation-provider
            #default="validationContext"
            :name="$t('generic_devices.form.provider.label')"
            rules="required"
          >
            <b-form-group
              :label="$t('generic_devices.form.provider.label')"
              label-for="provider"
            >
              <b-form-input
                id="provider"
                v-model="itemData.provider"
                :placeholder="$t('generic_devices.form.provider.placeholder')"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Center -->
          <validation-provider
            #default="validationContext"
            :name="$tc('centers.title', 1)"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('centers.title', 1)"
              label-for="id_center"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_center"
                :options="centerOptions"
                :reduce="(val) => val.value"
                input-id="id_center"
                @input="changeCenter"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sectors -->
          <validation-provider
            #default="validationContext"
            :name="$tc('sectors.title', 1)"
            rules="required"
          >
            <b-form-group
              :label="$tc('sectors.title', 1)"
              label-for="id_sector"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_sector"
                :options="sectorOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="id_sector"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Units -->
          <validation-provider
            #default="validationContext"
            :name="$t('generic_devices.units')"
            rules="required"
          >
            <b-form-group
              :label="$t('generic_devices.units')"
              label-for="units"
              :state="getValidationState(validationContext)"
            >
              <select-paginate
                label="description"
                v-model="itemData.units"
                :items="units"
                input-id="units"
                :multiple="true"
                @change="updateDeviceTags($event)"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Omniview reseller -->
          <validation-provider
            #default="validationContext"
            :name="$t('generic_devices.reseller')"
            rules="required"
          >
            <b-form-group
              :label="$t('generic_devices.reseller')"
              label-for="reseller"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_omniview_reseller"
                :options="resellerOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="reseller"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Camera Types -->
          <validation-provider
            #default="validationContext"
            :name="$t('generic_devices.camera_type')"
            rules="required"
          >
            <b-form-group
              :label="$t('generic_devices.camera_type')"
              label-for="camera_type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_type"
                :options="cameraTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="camera_type"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tags -->
          <validation-provider #default="validationContext" name="tag">
            <b-form-group
              class="text-capitalize"
              label="Tag"
              label-for="id_device_tag"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_device_tag"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deviceTagsOptions"
                :clearable="true"
                :reduce="(val) => val.value"
                input-id="id_device_tag"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Enable RSTP -->
          <b-form-checkbox
            id="rtsp_available"
            v-model="itemData.rtsp_available"
            name="rtsp_available"
            value="1"
            class="my-1 text-capitalize"
            unchecked-value="0"
            switch
          >
            {{ $t("camera_proxy.rtsp_available") }}
          </b-form-checkbox>

          <p class="mt-2 mb-0">{{ $t("generic_devices.device_config") }}</p>
          <hr />

          <!-- Device Type -->
          <validation-provider
            #default="validationContext"
            :name="$t('generic_devices.form.type.label')"
            rules="required"
          >
            <b-form-group
              :label="$t('generic_devices.form.type.label')"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.type"
                :placeholder="$t('generic_devices.form.type.placeholder')"
                :options="typeOptions"
                :clearable="false"
                :reduce="(type) => type.value"
                input-id="type"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Custom fields -->
          <div v-for="(field, index) in Object.keys(customFieldsSchema)" :key="index">
            <p class="mt-2 mb-0" v-if="customFieldsSchema[field].label === 'generic_devices.form.custom_fields.onvif_host.label'">{{ $t("generic_devices.onvif") }}</p>
            <hr />
            <b-form-checkbox
              v-if="customFieldsSchema[field].type === 'checkbox'"
              :id="`cf_${field}`"
              v-model="itemData.data[field]"
              :name="`cf_${field}`"
              value="1"
              unchecked-value="0"
              switch
            >
              {{ $t(customFieldsSchema[field].label) }}
            </b-form-checkbox>
            <label v-if="customFieldsSchema[field].type !== 'checkbox'" 
              :for="`cf_${field}`">{{ $t(customFieldsSchema[field].label) }}</label>
            <b-form-input
              v-if="customFieldsSchema[field].type === 'text'"
              :id="`cf_${field}`"
              :type="customFieldsSchema[field].type"
              v-model="itemData.data[field]"
              :placeholder="$t(customFieldsSchema[field].label)"
              trim
              class="mb-1"
            />
            <b-form-input
              v-if="customFieldsSchema[field].type === 'number'"
              :id="`cf_${field}`"
              :type="customFieldsSchema[field].type"
              v-model="itemData.data[field]"
              :placeholder="$t(customFieldsSchema[field].label)"
              trim
              class="mb-1"
            />
            <v-select
              v-if="customFieldsSchema[field].type === 'select'"
              v-model="itemData.data[field]"
              :placeholder="$t(customFieldsSchema[field].label)"
              :options="customFieldsSchema[field].options"
              :clearable="false"
              :reduce="(opt) => opt.value"
              :input-id="`cf_${field}`"
              class="mb-1"
            />
          </div>

          <hr />

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import axios from "@/libs/axios";
import SelectPaginate from "../../components/SelectPaginate.vue";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    SelectPaginate,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    centerOptions: {
      typeof: Array,
      required: true,
    },
    sectorOptions: {
      typeof: Array,
      required: true,
    },
    units: {
      typeof: Array,
      required: true,
    },
    cameraTypeOptions: {
      type: Array,
      required: true,
    },
    resellerOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      name: null,
      provider: null,
      type: null,
      device: {},
      data: {},
      id_sector: null,
      id_device_tag: null,
      rtsp_available: false,
      id_omniview_reseller: null,
      units: [],
      id_type: null,
    };

    const customFieldsSchema = {
      url: {
        label: "generic_devices.form.custom_fields.url.label",
        type: "text",
      },
      protocol: {
        label: "generic_devices.form.custom_fields.protocol.label",
        type: "select",
        options: [
          { value: "rtsp", label: "RTSP" },
          { value: "rtmp", label: "RTMP" },
        ],
      },
      port: {
        label: "generic_devices.form.custom_fields.port.label",
        type: "number",
      },
      onvif_host: {
        label: "generic_devices.form.custom_fields.onvif_host.label",
        type: "text",
      },
      onvif_port: {
        label: "generic_devices.form.custom_fields.onvif_port.label",
        type: "number",
      },
      user: {
        label: "generic_devices.form.custom_fields.user.label",
        type: "text",
      },
      password: {
        label: "generic_devices.form.custom_fields.password.label",
        type: "text",
      },
      enabled_onvif_events: {
        label: "generic_devices.form.custom_fields.enabled_onvif_events.label",
        type: "checkbox",
      },
    };

    onMounted(async () => {});

    // Composables
    const toast = useToast();
    const { t } = useI18nUtils();

    // Data
    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const typeOptions = ref([
      {
        value: "camera",
        label: t("generic_devices.types.camera"),
      },
      {
        value: "generic_device",
        label: t("generic_devices.types.generic_device"),
      },
    ]);
    const sectors = ref([]);

    // Computed
    const titleForm = computed(() => (props.isAdd ? "Add" : "Edit"));

    // Methods
    const contentToast = (message, type) =>
      toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "CheckIcon",
          variant: type,
        },
      });

    const changeCenter = () => {
      store
        .dispatch("sector/getAll", {
          id_center: [itemData.value.id_center],
        })
        .then(() => {
          const sector = props.sectorOptions.filter(
            (item) => item.value == itemData.value.id_sector
          );

          if (sector.length == 0) itemData.value.id_sector = "";
        });
    };

    const onSubmit = async () => {
      try {
        let message = "";
        if (props.isAdd) {
          let { data } = await axios.post(`/v1/generic-devices`, { ...itemData.value });
          message = data.message;
        } else {
          let { data } = await axios.put(`/v1/generic-devices/${itemData.value.id}`, {
            ...itemData.value,
          });
          message = data.message;
        }

        contentToast(message, "success");
        emit("refetch-data");
        emit("update:is-sidebar-active", false);
      } catch (error) {
        let { message } = error.response.data;

        contentToast(message, "danger");
      }
    };

    const deviceTagsOptions = computed(() => {
      return store.getters["tenant/getDeviceTags"].map((item) => ({
        label: item.name,
        value: item.id,
      }));
    });

    const updateDeviceTags = (unitsParam) => {
      if (!unitsParam) return;
      let idsTenants = unitsParam.map((unit) => unit.id_tenant);
      store.dispatch("tenant/getDeviceTagsByTenantIds", { id_tenants: idsTenants });
    };

    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
        if (itemData.value.units.length) {
          itemData.value.units = props.itemEdit.units.map((value) => ({
            value: value.id,
            label: value.description,
            id_tenant: value.router.id_tenant,
          }));
          updateDeviceTags(itemData.value.units);
        }
      }
      changeCenter();
    };

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      loadData,
      changeCenter,
      resetForm,
      typeOptions,

      updateDeviceTags,
      titleForm,
      deviceTagsOptions,
      customFieldsSchema,
    };
  },
};
</script>
